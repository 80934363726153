<template>
  <lazy-youtube-video :src="src" :previewImageSize="previewImageSize" :webp="false" />
</template>

<script>
import LazyYoutubeVideo from 'vue-lazy-youtube-video';

export default {
  props: {
    src: String,
    previewImageSize: String,
  },
  components: {LazyYoutubeVideo},
};
</script>

<style lang="scss" scoped>
.y-video {
  clear: both;
}
</style>
